import posthog from 'posthog-js';
import { browser } from '$app/environment';
import type { LayoutLoad } from './$types';
import { QueryClient } from '@tanstack/svelte-query';

export const load: LayoutLoad = async () => {
	if (browser) {
		posthog.init('phc_iEEaUAOwn6Ay8gV1JwYhrIrFNk2833O6ccb4DzPIBnw', {
			api_host: 'https://analytics.playpark.se',
			person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
			persistence: 'memory'
		});
	}
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				enabled: browser,
				staleTime: 60 * 1000
			}
		}
	});

	return { queryClient };
};
