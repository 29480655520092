<script lang="ts">
	import { run } from 'svelte/legacy';

	import { apiFetch, type apiResponse } from '$lib/utils/fetch';
	import { useQueryClient, createQuery } from '@tanstack/svelte-query';
	import { openModal, closeAllModals, closeModal, modals } from 'svelte-modals';
	import Modal from '$lib/components/Modal.svelte';
	import Renew from '$lib/components/auth/Renew.svelte';
	import { authenticated, user, user_info } from '$lib/stores/user';
	import { derived } from 'svelte/store';
	import ActivateAccountReminder from './ActivateAccountReminder.svelte';
	import { page } from '$app/stores';
	import { toast } from 'svelte-sonner';
	import { permissions, type Permissions } from '$lib/stores/permissions';
	import type { UserInfo } from '$lib/stores/user';

	const client = useQueryClient();

	interface userInfoResponse extends apiResponse {
		data: UserInfo;
	}

	const userInfoQuery = createQuery<{ userinfo: userInfoResponse }>({
		queryKey: ['user_info'],
		enabled: $authenticated,
		refetchInterval: 30000,
		queryFn: async () =>
			await apiFetch('/auth/status', {
				method: 'GET'
			})
				.then((fetchData) => {
					user_info.set(fetchData.data);
					permissions.set(fetchData.data.permissions);
					return { userinfo: fetchData };
				})
				.catch((error) => {
					return { userinfo: error };
				})
	});
	// Consume the query, needed to make it actually load
	const userInfoData = derived(
		userInfoQuery,
		($userInfoQuery) => $userInfoQuery.data?.userinfo.data
	);

	run(() => {
		switch ($authenticated) {
			case true:
				// console.log("Authenticated")
				client.invalidateQueries({ queryKey: ['user_info'] });
				client.fetchQuery({ queryKey: ['user_info'] });
				break;
		}
	});

	// Reactively check if the user needs to renew their membership

	const renewCheckData = derived(
		userInfoData,
		($userInfo) =>
			$userInfo?.active === true &&
			$userInfo?.renewed === false &&
			$userInfoQuery.isFetching === false
	);

	// Check if the user needs to activate their account

	const activevateAccountCheck = derived(
		userInfoData,
		($userInfo) => $userInfo?.active === false && $userInfoQuery.isFetching === false
	);

	// Check if the user is not logged in

	const notLoggedInCheck = derived(
		userInfoQuery,
		($userInfo) =>
			$userInfo.data?.userinfo.message === 'You are not logged in' && $userInfo.isFetching === false
	);

	run(() => {
		switch ($notLoggedInCheck) {
			case true:
				$user = null;
				$user_info = null;
				$permissions = null;
				toast.warning('Din session har gått ut');
				break;
		}
	});

	run(() => {
		switch ($renewCheckData) {
			case true:
				// console.log("Renew check is true")
				openModal(
					Modal,
					{ component: Renew, props: {} },
					{
						replace: true
					}
				);
				break;
			case false:
				// console.log("Renew check is false")
				break;
			case undefined:
				break;
		}
	});

	run(() => {
		switch ($activevateAccountCheck) {
			case true:
				if ($authenticated && $page.url.pathname.includes('activate')) {
					break;
				}
				openModal(
					Modal,
					{ component: ActivateAccountReminder, props: {} },
					{
						replace: true
					}
				);
				break;
			case false:
				break;
			case undefined:
				break;
		}
	});
</script>
