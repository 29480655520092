<script lang="ts">
	import { createBubbler } from 'svelte/legacy';

	const bubble = createBubbler();
	import './styles.css';
	import '../app.css';
	import './fonts.css';
	import { Modals, closeModal } from 'svelte-modals';
	import { Toaster } from 'svelte-sonner';
	import '@fontsource-variable/inter';

	import { browser } from '$app/environment';
	import { QueryClientProvider } from '@tanstack/svelte-query';
	import { SvelteQueryDevtools } from '@tanstack/svelte-query-devtools';
	import RenewCheck from '$lib/components/auth/statusCheck.svelte';

	// Ensure the app is updated at all times
	import { beforeNavigate } from '$app/navigation';
	import { updated } from '$app/stores';
	import type { PageData } from './$types';
	interface Props {
		data: PageData;
		children?: import('svelte').Snippet;
	}

	let { data, children }: Props = $props();

	// If clientside send cool message
	if (browser) {
		console.log(String.raw`	
 ______  _                                _     
(_____ \| |                              | |    
 _____) ) | _____ _   _ ____  _____  ____| |  _ 
|  ____/| |(____ | | | |  _ \(____ |/ ___) |_/ )
| |     | |/ ___ | |_| | |_| / ___ | |   |  _ ( 
|_|      \_)_____|\__  |  __/\_____|_|   |_| \_)
                 (____/|_|                      

Är du nyfiken? Gå in på vår https://discord.playpark.se
och engagera dig i utveckligen, fråga oss!

		`);
	}

	beforeNavigate(({ willUnload, to }) => {
		if ($updated && !willUnload && to?.url) {
			location.href = to.url.href;
		}
	});
</script>

<svelte:head>
	<title>Playpark</title>
	<meta name="description" content="Playpark" />
	<meta name="og:title" content="Playpark" />
	<meta name="og:description" content="Playpark Spelförening" />
</svelte:head>

<QueryClientProvider client={data.queryClient}>
	<div class="app">
		<main>
			<div class="w-full">
				{@render children?.()}
			</div>
		</main>
	</div>
	<RenewCheck />
	<SvelteQueryDevtools />

	<Modals>
		<!-- svelte-ignore a11y_no_static_element_interactions -->
		{#snippet backdrop()}
				<div  class="backdrop" onclick={closeModal} onkeypress={bubble('keypress')}>{''}</div>
			{/snippet}
	</Modals>

	<Toaster
		position="bottom-center"
		class="toaster group"
		toastOptions={{
			classes: {
				toast:
					'group toast group-[.toaster]:bg-zinc-900/70 backdrop-blur group-[.toaster]:text-white group-[.toaster]:border-zinc-800 group-[.toaster]:shadow-lg group-[.toaster]:drop-shadow'
			}
		}}
	/>
</QueryClientProvider>

<style lang="scss">
	.backdrop {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.8);
		backdrop-filter: blur(4px);
		z-index: 1;
	}
	.app {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		background: #000;
		width: 100vw;
	}

	main {
		flex: 1;
		display: flex;
		width: 100%;
		box-sizing: border-box;
	}

	:global(:root) {
		font-family: 'Inter Variable', sans-serif;
	}
</style>
